<template>
  <div class="singleElevatorReport">
    <div class="reportContent" v-loading="loading">
      <!-- 大标题 -->
      <div class="title">
        <span>电梯AI维保系统单梯统计报告</span>
        <el-button class="downLoadBtn" @click="downLoad">下载</el-button>
      </div>
      <!-- 统计周期 -->
      <div class="reportDate">
        <span class="range">统计周期</span>
        <el-date-picker v-model="reportDate" class="datePicker" value-format="yyyy-MM-dd" :clearable="false"
          type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange"
          :picker-options="pickerOptions"></el-date-picker>
        <el-button class="createReport" @click="createReport">生成报告</el-button>
      </div>
      <!-- 电梯信息 -->
      <div class="elevatorInfo">
        <!-- 健康指数 -->
        <div class="health" v-if="elevatorRunInfo.onlineRate">
          <div class="healthValue">{{ elevatorRunInfo.health ? elevatorRunInfo.health : 100 }}</div>
          <div class="healthName">健康指数</div>
        </div>
        <!-- 电梯名称、电梯编号、使用单位 -->
        <div class="basicInfo" :style="elevatorRunInfo.onlineRate ? { marginLeft: '124px' } : ''">
          <div class="elevatorName">
            <span class="blue" style="font-size: 20px;">{{ elevatorInfo.name }}</span>
            <span class="links">
              <router-link :to="'/elevator/' + elevatorId" target="_blank">电梯详情</router-link>
              <span style="margin: 0 5px;color: #ccc;">|</span>
              <router-link :to="'/singleMonitor/' + elevatorId" target="_blank">单梯监控</router-link>
            </span>
          </div>
          <p>电梯编号：{{ elevatorInfo.no }}</p>
          <p>使用单位：{{ elevatorInfo.useUnitName }}</p>
        </div>
        <!-- 本期摘要 -->
        <div class="abstract">
          <p>[ 本期摘要 ]</p>
          <p>{{ abstract }}<el-button class="createReport" @click="edit" style="margin-left: 10px;">编辑</el-button></p>
          <p>1、{{ reportDate[0] }} 至 {{ reportDate[1] }}，本电梯共运行 <span class="blue">{{ elevatorRunInfo.runningCount ?
            elevatorRunInfo.runningCount : 0
              }}</span> 次，运行距离 <span class="blue">{{ elevatorRunInfo.runDistanceOfKilometers ?
                elevatorRunInfo.runDistanceOfKilometers : 0 }}</span>
            千米，开关门 <span class="blue">{{ elevatorRunInfo.doorOpenCount ? elevatorRunInfo.doorOpenCount : 0 }}</span> 次。
          </p>
          <p>2、运行时间最长的一<span>{{ type === "day" ? "天" : type === "week" ? "周" : "个月" }}</span>是{{ runningTimeMax.name
            }}，当<span>{{ type === "day" ? "天" : type === "week" ? "周" : "月" }}</span>运行 <span class="blue">{{
              runningTimeMax.value }}</span> 小时，是最忙碌的一<span>{{ type === "day" ? "天" : type === "week" ? "周" : "个月"
              }}</span>。
          </p>
          <p>3、运行次数最多的一<span>{{ type === "day" ? "天" : type === "week" ? "周" : "个月" }}</span>是{{ runningCountMax.name
            }}，当<span>{{ type === "day" ? "天" : type === "week" ? "周" : "月" }}</span>运行 <span class="blue">{{
              runningCountMax.value }}</span>
            次，繁忙程度超过全平台其他
            <span class="blue">{{ busynessRate ? busynessRate : 0 }}%</span> 的电梯。
          </p>
          <p>4、停靠次数最多的楼层是 <span class="blue">{{ eachFloorStopCountDataMax.name }}</span> 楼，我们会安排维保工程师更加关注该层的检查。</p>
          <p>5、累计发生故障 <span class="red">{{ elevatorRunInfo.accumulatedFaultCount ? elevatorRunInfo.accumulatedFaultCount : 0 }}</span>
            次。<span v-if="elevatorRunInfo.accumulatedFaultCount">故障原因为:<span v-for="(item, index) in faultType" :key="item.id">{{ index === faultType.length - 1 ? item.name
              + "，" : item.name + "、" }} </span>维保工程师已经为您检查并修复。</span></p>
          <p v-if="elevatorRunInfo.onlineRate">6、本电梯健康指数 <span class="blue">{{ elevatorRunInfo.health ?
            elevatorRunInfo.health : 100 }}</span>
            ，温馨保养建议:
            <table style="width: 100%;" v-if="getAdvice.length > 0">
              <tr v-for="( item,index ) in getAdvice" :key="index" :class="['adviceItem', index % 2 === 1 ? 'stripe' : '']">
                <td>{{ `（${ index + 1 }）${ item.value }` }}</td>
              </tr>
            </table>
            <span v-else>按照标准正常保养。</span>
          </p>
        </div>
        <!-- 运行次数、运行距离、开关门次数、终端在线率 -->
        <div class="statisticsItems">
          <el-row :gutter="16">
            <el-col :span="6">
              <div class="grid-content">
                <div>
                  <p>运行次数</p>
                  <p><span class="blue itemValue">{{ elevatorRunInfo.runningCount ? elevatorRunInfo.runningCount : 0
                      }}</span> 次</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <div>
                  <p>运行距离</p>
                  <p><span class="blue itemValue">{{ elevatorRunInfo.runDistanceOfKilometers ?
                    elevatorRunInfo.runDistanceOfKilometers : 0 }}</span> 千米</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <div>
                  <p>开关门次数</p>
                  <p><span class="blue itemValue">{{ elevatorRunInfo.doorOpenCount ? elevatorRunInfo.doorOpenCount : 0
                      }}</span> 次</p>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <div>
                  <p>终端在线率</p>
                  <p><span class="blue itemValue">{{ elevatorRunInfo.onlineRate ? elevatorRunInfo.onlineRate : 0
                      }}</span> %</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 阶段性电梯运行报告 -->
      <div class="smallTitle prevent-page-break-inside" v-if="elevatorRunInfo.runningCount">
        <img style="width: 32px; height: 32px;margin-right: 12px;" src="../../assets/ai/dtyx@2x.png"/>
        <span>阶段性电梯运行报告</span>
      </div>
      <!-- 运行时间 -->
      <run-time :data="runningTime" class="prevent-page-break-inside"></run-time>
      <!-- 运行次数 -->
      <run-count :data="runningCount" class="prevent-page-break-inside"></run-count>
      <!-- 运行距离 -->
      <run-distance :data="runDistance" class="prevent-page-break-inside"></run-distance>
      <!-- 开关门次数 -->
      <door-count :data="doorOpenCount" class="prevent-page-break-inside"></door-count>
      <!-- 每层停靠次数 -->
      <each-floor-stop-count :data="eachFloorStopCountData" class="prevent-page-break-inside"></each-floor-stop-count>
      <!-- 故障统计报告 -->
      <div class="smallTitle prevent-page-break-inside">
        <img style="width: 32px; height: 32px;margin-right: 12px;" src="../../assets/ai/gztj@2x.png"/>
        <span>故障统计报告</span>
      </div>
      <!-- 检修次数 -->
      <repair-count :data="repairCount" class="prevent-page-break-inside"></repair-count>
      <!-- 故障统计指标 -->
      <div class="faultItems prevent-page-break-inside">
        <el-row type="flex" justify="space-between">
          <el-col :span="4">
            <div class="grid-content">
              <div>
                <p>困人次数</p>
                <p><span class="blue itemValue">{{ elevatorRunInfo.faultTrappingCount ?
                  elevatorRunInfo.faultTrappingCount : 0 }}</span> 次</p>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content">
              <div>
                <p>故障停梯次数</p>
                <p><span class="blue itemValue">{{ elevatorRunInfo.faultStopCount ? elevatorRunInfo.faultStopCount : 0
                    }}</span> 次</p>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content">
              <div>
                <p>故障停梯时长</p>
                <p><span class="blue itemValue">{{ elevatorRunInfo.faultStopTime ? elevatorRunInfo.faultStopTime : 0
                    }}</span> 分钟</p>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content">
              <div>
                <p>检修时长</p>
                <p><span class="blue itemValue">{{ elevatorRunInfo.overhaulTime ? elevatorRunInfo.overhaulTime : 0
                    }}</span> 分钟</p>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content">
              <div>
                <p>故障率（PPM，百万分之）</p>
                <p><span class="blue itemValue">{{ elevatorRunInfo.faultRate ? elevatorRunInfo.faultRate : 0 }}</span>
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 故障类型 -->
      <fault-type :faultType="faultType" class="prevent-page-break-inside"></fault-type>
      <!-- 故障原因及解决办法 -->
      <el-table class="prevent-page-break-inside" :data="faultReason" style="width: 100%;" v-if="faultType.length > 0">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div style="padding-left: 48px;">
              <el-table :data="scope.row.solutionSettings" style="width: 100%;">
                <el-table-column prop="index" label="序号" width="60" align="center"></el-table-column>
                <el-table-column prop="reason" label="故障原因" width="200"></el-table-column>
                <el-table-column prop="solution" label="解决方案"></el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="故障原因及解决办法"></el-table-column>
      </el-table>
      <!-- 维保及用梯建议 -->
      <div class="smallTitle prevent-page-break-inside" v-if="faultType.length > 0">
        <img style="width: 32px; height: 32px;margin-right: 12px;" src="../../assets/ai/wbjjy@2x.png"/>
        <span>维保及用梯建议</span>
      </div>
      <el-table class="prevent-page-break-inside" :data="mainTenanceData" style="width: 100%"
        v-if="faultType.length > 0">
        <el-table-column prop="name" label="电梯异常" width="400"></el-table-column>
        <el-table-column prop="value" label="建议"></el-table-column>
      </el-table>
      <!-- 签字区 -->
      <div class="footer prevent-page-break-inside">
        <div class="signBox">
          <div>签字区</div>
          <div></div>
        </div>
      </div>
      <!-- 摘要编辑框 -->
      <el-dialog title="编辑摘要引言" :visible.sync="dialogVisible" width="50%">
        <el-input v-model="newAbstract" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="sureHandle">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import RunTime from "./echarts/RunTime.vue";
import RunCount from "./echarts/RunCount.vue";
import RunDistance from "./echarts/RunDistance.vue";
import DoorCount from "./echarts/DoorCount.vue";
import EachFloorStopCount from "./echarts/EachFloorStopCount.vue";
import RepairCount from "./echarts/RepairCount.vue";
import FaultType from "./echarts/FaultType.vue";
export default {
  components: { RunTime, RunCount, RunDistance, DoorCount, EachFloorStopCount, RepairCount, FaultType },
  data() {
    return {
      busynessRate: "", // 繁忙程度
      dialogVisible: false,
      abstract: "请您查收本电梯运行报告。电梯云守护您的电梯健康！",
      newAbstract: "",
      type: "day",
      loading: false,
      elevatorId: "", // 当前电梯id
      elevatorInfo: {}, // 电梯基本信息
      elevatorRunInfo: {},
      runningTime: [],
      runningTimeMax: {},
      runningCount: [],
      runningCountMax: {},
      runDistance: [],
      doorOpenCount: [],
      eachFloorStopCountData: [],
      eachFloorStopCountDataMax: {},
      repairCount: [],
      faultType: [],
      faultReason: [],
      mainTenanceData: [],
      reportDate: [this.$moment().subtract(15, "days").format("YYYY-MM-DD"), this.$moment().subtract(1, "days").format("YYYY-MM-DD")], // 统计周期
      pickerOptions: {
        disabledDate(time) {
          // 禁用所有当前日期之后的日期
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: "最近一周",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近一个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          }
        }, {
          text: "最近三个月",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          }
        }]
      },
    };
  },

  computed: {
    getAdvice() {
      return this.mainTenanceData.filter(item => item.value);
    }
  },

  created() {
    this.elevatorId = this.$route.params.id;
    this.getBasicInfoById();
    this.getEachFloorStopCount();
  },

  mounted() {
    this.getEchartsData();
  },

  methods: {
    // 摘要引言编辑
    edit() {
      this.dialogVisible = true;
      this.newAbstract = this.abstract;
    },

    sureHandle() {
      this.abstract = this.newAbstract;
      this.dialogVisible = false;
    },

    downLoad() {
      window.print();
    },

    // 修改统计周期
    dateChange(date) {
      this.reportDate = date;
    },

    // 生成报告
    createReport() {
      this.mainTenanceData = [];
      this.runningTime = [];
      this.runningCount = [];
      this.runDistance = [];
      this.doorOpenCount = [];
      this.repairCount = [];
      this.eachFloorStopCountData = [];
      this.faultType = [];
      this.getBasicInfoById();
      this.getEachFloorStopCount();
      this.getEchartsData();
    },

    // 根据id获取电梯基本信息
    getBasicInfoById() {
      this.loading = true;
      this.$http.get(`elevators/${this.elevatorId}`).then(res => {
        this.elevatorInfo = res.data;
        this.getElevatorRunInfo();
      }).catch(() => {
        this.loading = false;
      });
    },

    // 获取电梯的运行信息
    getElevatorRunInfo() {
      const params = {
        elevatorId: this.elevatorId,
        startTime: this.reportDate[0],
        endTime: this.reportDate[1]
      };
      this.$http.post("maintenanceReport/countReportBySingle", params).then(res => {
        this.elevatorRunInfo = res.data;
        this.elevatorRunInfo.elevatorId = params.elevatorId;
        this.elevatorRunInfo.startTime = params.startTime;
        this.elevatorRunInfo.endTime = params.endTime;
        this.getFaultType();
        this.getReasonAndSolution();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    // 图表数据：运行时间、运行次数、运行距离、开关门次数、检修次数
    getEchartsData() {
      const params = {
        elevatorId: this.elevatorId,
        startTime: this.reportDate[0],
        endTime: this.reportDate[1]
      };
      this.$http.post("maintenanceReport/countReportDetailBySingle", params).then(res => {
        this.type = res.data.type;
        const result = res.data.data;
        result.forEach(item => {
          if( this.type === "week" ) {
            item.name = this.$moment(item.value.startDate).format("YYYY.M.D") + "-" + this.$moment(item.value.endDate).format("M.D");
          } 
          this.runningTime.push({ name: item.name, value: item.value.runningTime ? (item.value.runningTime / 60).toFixed(2) : 0 });
          this.runningCount.push({ name: item.name, value: item.value.runningCount, info: item.value });
          this.runDistance.push({ name: item.name, value: item.value.runDistanceOfKilometers });
          this.doorOpenCount.push({ name: item.name, value: item.value.doorOpenCount });
          this.repairCount.push({ name: item.name, value: item.value.overhauCount });
        });
        this.runningTimeMax = this.runningTime.reduce((max, current) => {
          return (max.value > current.value) ? max : current;
        }, this.runningTime[0]);
        this.runningCountMax = this.runningCount.reduce((max, current) => {
          return (max.value > current.value) ? max : current;
        }, this.runningCount[0]);
        this.getBusynessRate();
      });
    },

    // 获取繁忙层度
    getBusynessRate() {
      let params;
      if (this.type === "day") {
        params = {
          type: this.type,
          elevatorId: this.elevatorId,
          oneDay: this.runningCountMax.name
        };
      } else {
        params = {
          type: this.type,
          elevatorId: this.elevatorId,
          startTime: this.runningCountMax.info.startDate,
          endTime: this.runningCountMax.info.endDate
        };
      }
      this.$http.post("maintenanceReport/calculateBusynessRate", params).then(res => {
        // console.log("繁忙程度>>>>>>>>>>>>>>>>>>>>>>>>", res);
        this.busynessRate = res.data.busynessRate;
      });
    },

    // 获取每层停靠次数
    getEachFloorStopCount() {
      const params = {
        elevatorId: this.elevatorId,
        startTime: this.reportDate[0],
        endTime: this.reportDate[1]
      };
      this.$http.post("maintenanceReport/countReportFloorDetailBySingle", params).then(res => {
        const result = res.data;
        result.forEach(item => {
          this.eachFloorStopCountData.push({ name: item.name, value: item.value.doorStopCount });
        });
        this.eachFloorStopCountData.sort((a, b) => a.name - b.name);
        this.eachFloorStopCountDataMax = this.eachFloorStopCountData.reduce((max, current) => {
          return (max.value > current.value) ? max : current;
        }, this.eachFloorStopCountData[0]);
      });
    },

    // 获取故障类型
    getFaultType() {
      this.$http.post("maintenanceReport/getFaultTypeTopBySingle", this.elevatorRunInfo).then(res => {
        const newArr = res.data.map(item => {
          return { value: item.total, name: item.name };
        });
        this.faultType = newArr.sort((a, b) => b.total - a.total);
      });
    },

    // 获取故障原因及解决办法、维保及用梯建议
    getReasonAndSolution() {
      this.$http.post("maintenanceReport/countFaultRecordBySingle", this.elevatorRunInfo).then(res => {
        this.faultReason = res.data;
        this.faultReason.forEach(item => {
          item.value = item.faultMaintRecos[0] ? item.faultMaintRecos[0].maintReco : "";
          this.mainTenanceData.push(item);
        });
        this.mainTenanceData.forEach((item, index) => {
          if (item.value === "建议检查X层层门装置") {
            if (item.floorCode !== "" && item.floorCode !== null) {
              item.value = `建议检查${item.floorCode}层层门装置`;
            } else {
              item.value = "建议检查厅门锁装置";
            }
            this.$set(this.mainTenanceData, index, item);
          }
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.singleElevatorReport {
  min-height: 100%;
  background-color: #f2f2f2;
  padding-top: 15px;
  font-size: 14px;
  box-sizing: border-box;

  .blue {
    color: #0747FD;
    font-weight: 700;
  }

  .red {
    color: red;
    font-weight: 700;
  }

  p {
    margin: 12px 0;
  }

  .stripe {
    background-color: #F5F8FF;
  }

  .reportContent {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    color: #4D4D4D;
    border-radius: 12px;
    overflow: hidden;
    padding-bottom: 15px;

    .grid-content {
      background-color: #F5F8FF;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 16px;
      color: #4D4D4D;

      .itemValue {
        font-size: 24px;
      }
    }

    .title {
      height: 64px;
      background-color: #0747FD;
      line-height: 64px;
      color: #fff;
      text-align: center;
      position: relative;

      span {
        font-size: 20px;
        font-weight: bold;
      }

      .downLoadBtn {
        position: absolute;
        right: 24px;
        background-color: #00C291;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        font-size: 12px;
      }
    }

    .reportDate {
      text-align: right;
      padding: 20px 24px;

      .range {
        color: #4D4D4D;
      }

      .datePicker {
        margin: 0 12px;
      }

      .createReport {
        background-color: #0747FD;
        color: #fff;
        border: none;
      }
    }

    .elevatorInfo {
      border: 1px solid #CCCCCC;
      border-radius: 12px;
      margin: 0 24px;
      padding: 24px;
      position: relative;

      .health {
        width: 100px;
        height: 120px;
        border-radius: 12px;
        position: absolute;
        left: 24px;
        top: -24px;
        text-align: center;
        overflow: hidden;

        .healthValue {
          height: 88px;
          background-color: #00C291;
          line-height: 88px;
          color: #fff;
          font-size: 44px;
        }

        .healthName {
          height: 32px;
          background-color: #B2EDDE;
          line-height: 32px;
        }
      }

      .basicInfo {
        min-height: 74px;

        p {
          margin: 8px 0;
        }

        .elevatorName {
          display: flex;
          justify-content: space-between;
        }
      }

      .abstract {
        margin: 24px 0 10px 0;
      }

      .adviceItem {
        height: 35px;
        font-size: 12px;
        color: #1A1A1A;
        line-height: 35px;
      }
    }

    .smallTitle {
      width: 260px;
      height: 48px;
      background-color: #00C291;
      border-radius: 0px 10px 50px 0px;
      color: #fff;
      display: flex;
      align-items: center;
      padding-left: 24px;
      box-sizing: border-box;
      margin-top: 40px;
    }

    .faultItems {
      padding: 24px;
    }

    .footer {
      display: flex;
      justify-content: end;
      padding: 0 24px;
      text-align: center;

      .signBox {
        box-sizing: border-box;
        padding-top: 12px;
        margin: 24px 0;
        width: 400px;
        height: 120px;
        border: 1px solid #CCCCCC;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

// 打印样式
@media print {

  @page {
    margin: 0 !important;
    /* 设置打印时的每页边距为无 */
  }

  .singleElevatorReport {
    padding: 0;

    .createReport,
    .downLoadBtn,
    .links {
      display: none;
    }
  }

  .prevent-page-break-inside {
    page-break-inside: avoid;
  }
}
</style>